<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="上下架状态">
                                <el-select v-model="search.isall" placeholder="请选择类型" style="width: 100%">
                                    <el-option label="全部" :value=1></el-option>
                                    <el-option label="已开放城市" :value=0></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                                   @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                                   @click="issearch = !issearch">显示搜索
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--内容-->
            <el-form size="medium" label-width="50px">
                <el-form-item v-loading="loading" label="">
                    <el-tree
                            :default-checked-keys="select_control"
                            class="region-all"
                            node-key="region_code"
                            ref="tree"
                            :data="region_data"
                            :props="props"
                            show-checkbox
                            @check-change="handleCheckChange">
                    </el-tree>
                </el-form-item>
                <div style="height: 30px;"></div>
                <el-form-item>
                    <el-button v-if="this.Tool.is_auth('systemset.cityset.issave')" size="medium" type="primary"
                               @click="save">保存
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            issearch: false,
            search:{isall:1},
            page_name: '城市设置',
            loading: true,
            region_data: [],            // 地区信息
            props: {
                label: 'region_name',
                children: 'children',
            },
            select_control: [],         // 选中的菜单
            open_region: [],            // 开通的地区
        }
    },
    // 创建
    created() {
        this.getregion()
    },
    // 安装
    mounted() {
        this.getinfo()
    },
    methods: {
        // 获取城市信息
        getregion() {
            let postdata = {
                api_name: 'home.region.getlist',
                token: this.Tool.get_l_cache('token'),
                region_level: 'all',
                region_code: '0',
                onlyallow: '0',
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.region_data = json.data
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 获取已开通城市列表
        getcityset() {
            let postdata = {
                api_name: 'systemset.cityset.getlist',
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.region_data = json.data
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        handleCheckChange() {
            let CheckedKeys = this.$refs.tree.getCheckedKeys()
            let HalfCheckedKeys = this.$refs.tree.getHalfCheckedKeys()
            // console.log('选中的节点', CheckedKeys)
            // console.log('半选中节点', HalfCheckedKeys)
            this.select_control = CheckedKeys
            this.control = HalfCheckedKeys.concat(CheckedKeys)
        },
        // 获取设置的信息
        getinfo() {
            let postdata = {
                api_name: 'systemset.cityset.getinfo',
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.select_control = json.data.select_control
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 保存
        save() {
            let postdata = {
                api_name: 'systemset.cityset.issave',
                token: this.Tool.get_l_cache('token'),
                control: this.control,
                select_control: this.select_control,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '保存成功', type: 'success',
                        duration: this.env.message_duration,
                    });
                } else {
                    this.$message.error(json.message);
                }
            })

        },
        is_search() {
            this.search.isall?this.getregion():this.getcityset()
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.region-all {

}

>>> .region-all .el-tree-node {
    display: flex;
    align-items: center;
}

>>> .region-all > .el-tree-node {
    /*align-items: center;*/
    border-bottom: 1px #DCDFE6 solid;
    padding-top: 10px;
    padding-bottom: 10px;
}

/*一级节点*/
>>> .region-all > .el-tree-node > .el-tree-node__content {

}

/*二级节点*/
>>> .region-all > .el-tree-node > .el-tree-node__children > .el-tree-node > .el-tree-node__content {
    /*background: #3a8ee6;*/

}

/*三级节点*/
>>> .region-all > .el-tree-node > .el-tree-node__children > .el-tree-node > .el-tree-node__children {
    /*background: #51e652;*/
    margin-top: 8px;
    margin-bottom: 8px;
}

>>> .region-all > .is-expanded > .is-expanded {
    /*border-left: 1px #DCDFE6 solid;*/
}

>>> .region-all > .el-tree-node > .el-tree-node__children > .el-tree-node > .el-tree-node__children {
    display: flex;
    flex-wrap: wrap;
}

/*   >>> .region-all >.el-tree-node .el-tree-node__children >div[tabindex="-1"] {
       border-bottom: 1px #DCDFE6 solid;
       padding-top: 10px;
       padding-bottom: 10px;
   }*/


>>> .region-all .el-tree-node__label {
    /*width: 200px;*/
    min-width: 100px;
}

/*隐藏展开按钮*/
>>> .region-all .el-tree-node__expand-icon {
    /*display: none !important;*/

}


</style>
